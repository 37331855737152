<template>
  <v-dialog
    v-model="modalData.dialog"
    max-width="1450px"
    persistent
    scrollable
    :retain-focus="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        @click="$emit('new')"
      >
        {{ $t("MENU.NEW") }} {{ $t("MENU.PUBLICATION") }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }} </span>
            <v-btn @click="handleCloseModalForm" icon class="close-button">
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="formValid" ref="form">
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="2" md="2">
              <v-select
                v-model="selectedLocale"
                :items="languages"
                :disabled="languages.length == 1"
              >
                <template slot="selection" slot-scope="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="selectedLocale.flag" alt="" />
                  </span>
                  {{ slotProps.item.name }}
                </template>
                <template v-slot:item="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="slotProps.item.flag" alt="" />
                  </span>
                  <span class="navi-text">{{ slotProps.item.name }}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-select
                v-if="statuses.publications"
                v-model="formModel.status"
                :items="statuses.publications.publication_post"
                label="Status"
                item-text="value"
                item-value="key"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-if="formModel.translations[selectedLocale.lang]"
                v-model="formModel.translations[selectedLocale.lang].name"
                class="mt-4"
                :rules="nameRules"
                :label="$t('FORMS.name')"
                :id="dynamicID"
                :error-messages="
                  messages['translations.' + selectedLocale.lang + '.name']
                "
                @keyup="
                  messages['translations.' + selectedLocale.lang + '.name'] = ''
                "
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="3" md="3">
              <v-select
                v-model="formModel.categories"
                :items="publicationCategoryCollection"
                attach
                chips
                deletable-chips="true"
                item-text="name"
                item-value="id"
                :label="$t('MENU.PUBLICATION_CATEGORIES')"
                multiple
              >
                <template #selection="{ item }">
                  <v-chip
                    color="bg-gray-100"
                    close
                    @click:close="deleteChip(item, formModel.categories)"
                    >{{ item.name }}</v-chip
                  >
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-select
                v-model="formModel.type"
                :items="formModelTypes"
                class="mt-4"
              >
                <template slot="selection" slot-scope="slotProps">
                  {{ $t("SELECTS." + slotProps.item) }}
                </template>
                <template v-slot:item="slotProps">
                  <span class="navi-text">{{
                    $t("SELECTS." + slotProps.item)
                  }}</span>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6" v-if="[2].includes(formModel.type)">
              <v-text-field
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                v-model="
                  formModel.translations[selectedLocale.lang].custom_fields.url
                "
                class="mt-4"
                :rules="nameRules"
                label="URL"
                :id="dynamicID"
                :error-messages="
                  messages['translations.' + selectedLocale.lang + '.url']
                "
                @keyup="
                  messages['translations.' + selectedLocale.lang + '.url'] = ''
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              v-if="[1, 2, 3].includes(formModel.type)"
            >
              {{ $t("FORMS.intro") }}
              <ckeditor
                v-if="formModel.translations[selectedLocale.lang]"
                v-model="formModel.translations[selectedLocale.lang].intro"
                :error-messages="
                  messages['translations.' + selectedLocale.lang + '.intro']
                "
                @input="
                  messages['translations.' + selectedLocale.lang + '.intro'] =
                    ''
                "
                :config="editorConfig2"
              ></ckeditor>
              <small
                v-if="
                  messages['translations.' + selectedLocale.lang + '.intro']
                "
                style="color: red"
              >
                {{ messages["translations." + selectedLocale.lang + ".intro"] }}
              </small>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              v-if="
                [1, 3].includes(formModel.type) &&
                !formModel.categories.includes(7)
              "
            >
              {{ $t("FORMS.text") }}
              <ckeditor
                v-if="formModel.translations[selectedLocale.lang]"
                v-model="formModel.translations[selectedLocale.lang].text"
                :error-messages="
                  messages['translations.' + selectedLocale.lang + '.text']
                "
                @input="
                  messages['translations.' + selectedLocale.lang + '.text'] = ''
                "
                :config="editorConfig"
              ></ckeditor>
              <small
                v-if="messages['translations.' + selectedLocale.lang + '.text']"
                style="color: red"
              >
                {{ messages["translations." + selectedLocale.lang + ".text"] }}
              </small>
            </v-col>
          </v-row>

          <v-row v-if="formModel && formModel.categories.includes(7)">
            <v-col cols="12" sm="12" md="12">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                :config="{
                  type: 'accordion',
                  label: 'FORMS.accordion',
                  questionLabel: 'FORMS.accordionQuestion',
                  answerLabel: 'FORMS.accordionAnswer',
                }"
                customFieldName="accordion"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .accordion
                "
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
          </v-row>
        </v-form>

        <v-row>
          <v-col cols="12" v-if="[1, 3].includes(formModel.type)">
            <CustomFieldComponent
              v-if="
                formModel.translations[selectedLocale.lang] &&
                formModel.translations[selectedLocale.lang].custom_fields
              "
              :config="{ type: 'text', label: 'FORMS.seo_title' }"
              customFieldName="seoTitle"
              :value="
                formModel.translations[selectedLocale.lang].custom_fields
                  .seoTitle
              "
              @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
            >
            </CustomFieldComponent>
          </v-col>
          <v-col cols="12" v-if="[1, 3].includes(formModel.type)">
            <CustomFieldComponent
              v-if="
                formModel.translations[selectedLocale.lang] &&
                formModel.translations[selectedLocale.lang].custom_fields
              "
              :config="{
                type: 'textarea',
                label: 'FORMS.seo_description',
              }"
              customFieldName="seoDescription"
              :value="
                formModel.translations[selectedLocale.lang].custom_fields
                  .seoDescription
              "
              @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
            >
            </CustomFieldComponent>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
            sm="6"
            xs="12"
            v-if="[1, 3].includes(formModel.type)"
          >
            <CustomFieldComponent
              v-if="
                formModel.translations[selectedLocale.lang] &&
                formModel.translations[selectedLocale.lang].custom_fields
              "
              customFieldName="featured_image"
              :value="
                formModel.translations[selectedLocale.lang].custom_fields
                  .featured_image || ''
              "
              :config="{
                type: 'media_selector',
                maxFiles: 1,
                inputFields: [
                  {
                    name: 'alt_text',
                    label: 'FORM_INPUT_NAMES.alt_text',
                    type: 'text',
                  },
                  {
                    name: 'title',
                    label: 'FORM_INPUT_NAMES.title',
                    type: 'text',
                  },
                ],
                selectButtonText: 'FORM_INPUT_NAMES.featured_image',
                selectButtonTextNotTransalated: '',
                initialColumnClass:
                  'col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12',
              }"
              @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
            >
            </CustomFieldComponent>
          </v-col>
          <v-col
            cols="12"
            md="6"
            sm="6"
            xs="12"
            v-if="[1, 2, 3].includes(formModel.type)"
          >
            <CustomFieldComponent
              v-if="
                formModel.translations[selectedLocale.lang] &&
                formModel.translations[selectedLocale.lang].custom_fields
              "
              customFieldName="card_image"
              :value="
                formModel.translations[selectedLocale.lang].custom_fields
                  .card_image || ''
              "
              :config="{
                type: 'media_selector',
                maxFiles: 1,
                inputFields: [
                  {
                    name: 'alt_text',
                    label: 'FORM_INPUT_NAMES.alt_text',
                    type: 'text',
                  },
                  {
                    name: 'title',
                    label: 'FORM_INPUT_NAMES.title',
                    type: 'text',
                  },
                ],
                selectButtonText: 'FORM_INPUT_NAMES.card_image',
                initialColumnClass:
                  'col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12',
              }"
              @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
            >
            </CustomFieldComponent>
          </v-col>
        </v-row>
        <v-divider v-if="[1, 4].includes(formModel.type)"></v-divider>

        <v-row>
          <v-col cols="12" v-if="[1, 4].includes(formModel.type)">
            <CustomFieldComponent
              v-if="
                formModel.translations[selectedLocale.lang] &&
                formModel.translations[selectedLocale.lang].custom_fields
              "
              customFieldName="publication_items"
              :value="
                formModel.translations[selectedLocale.lang].custom_fields
                  .publication_items || ''
              "
              :config="{
                type: 'media_selector',
                maxFiles: -1,
                inputFields: [
                  {
                    name: 'title',
                    label: 'FORM_INPUT_NAMES.title',
                    type: 'text',
                  },
                  {
                    name: 'year',
                    label: 'FORM_INPUT_NAMES.year',
                    type: 'text',
                  },
                ],
                selectButtonText: 'FORM_INPUT_NAMES.select_publication_item',
                initialColumnClass:
                  'col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12',
              }"
              @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
            >
            </CustomFieldComponent>
            <!--<CustomFieldComponent
                v-if="formModel.translations[selectedLocale.lang]"
                customFieldName="publication_items"
                :value="
                  formModel.translations[selectedLocale.lang].publication_items || ''
                "
                :config="{
                  type: 'media_selector',
                  maxFiles: -1,
                  inputFields: [
                    {
                      name: 'alt_text',
                      label: 'FORM_INPUT_NAMES.alt_text',
                      type: 'text',
                    },
                    {
                      name: 'title',
                      label: 'FORM_INPUT_NAMES.title',
                      type: 'text',
                    },
                    {
                      name: 'description',
                      label: 'FORM_INPUT_NAMES.description',
                      type: 'text',
                    },
                    {
                      name: 'url',
                      label: 'FORM_INPUT_NAMES.url',
                      type: 'text',
                    },
                  ],
                  selectButtonText: 'FORM_INPUT_NAMES.select_publication_item',
                  initialColumnClass:
                    'col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12',
                }"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>-->
          </v-col>
        </v-row>
        <v-divider v-if="[1, 4].includes(formModel.type)"></v-divider>

        <v-row>
          <v-col cols="12" sm="4" md="4" v-if="[1, 3].includes(formModel.type)">
            <CustomFieldComponent
              v-if="
                formModel.translations[selectedLocale.lang] &&
                formModel.translations[selectedLocale.lang].custom_fields
              "
              :config="{
                type: 'text',
                label: 'FORM_INPUT_NAMES.og_site_name',
              }"
              customFieldName="og_site_name"
              :value="
                formModel.translations[selectedLocale.lang].custom_fields
                  .og_site_name || ''
              "
              @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
            >
            </CustomFieldComponent>
          </v-col>
          <v-col cols="12" sm="4" md="4" v-if="[1, 3].includes(formModel.type)">
            <CustomFieldComponent
              v-if="
                formModel.translations[selectedLocale.lang] &&
                formModel.translations[selectedLocale.lang].custom_fields
              "
              :config="{
                type: 'text',
                label: 'FORM_INPUT_NAMES.og_title',
              }"
              customFieldName="og_title"
              :value="
                formModel.translations[selectedLocale.lang].custom_fields
                  .og_title || ''
              "
              @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
            >
            </CustomFieldComponent>
          </v-col>
          <v-col cols="12" sm="4" md="4" v-if="[1, 3].includes(formModel.type)">
            <CustomFieldComponent
              v-if="
                formModel.translations[selectedLocale.lang] &&
                formModel.translations[selectedLocale.lang].custom_fields
              "
              customFieldName="og_image"
              :value="
                formModel.translations[selectedLocale.lang].custom_fields
                  .og_image || ''
              "
              :config="{
                type: 'media_selector',
                maxFiles: 1,
                selectButtonText: 'FORM_INPUT_NAMES.og_image',
                initialColumnClass:
                  'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12',
              }"
              @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
            >
            </CustomFieldComponent>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" md="4" v-if="[1, 3].includes(formModel.type)">
            <CustomFieldComponent
              v-if="formModel && formModel.custom_fields"
              :config="{
                type: 'multi_select',
                multiple: false,
                label: 'FORM_INPUT_NAMES.og_type',

                items: og_types,
              }"
              customFieldName="og_type"
              :value="formModel.custom_fields.og_type || 'article'"
              @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
            >
            </CustomFieldComponent>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" v-if="[1, 3].includes(formModel.type)">
            <CustomFieldComponent
              v-if="
                formModel.translations[selectedLocale.lang] &&
                formModel.translations[selectedLocale.lang].custom_fields
              "
              :config="{
                type: 'textarea',
                label: 'FORM_INPUT_NAMES.og_description',
              }"
              customFieldName="og_description"
              :value="
                formModel.translations[selectedLocale.lang].custom_fields
                  .og_description || ''
              "
              @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
            >
            </CustomFieldComponent>
          </v-col>
        </v-row>
      </v-card-text>
      <!-- <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn>
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="blue darken-1"
          text
          @click="handleSaveModalForm"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions> -->
      <v-card-actions class="mb-2">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn>
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="primary"
          elevation="2"
          @click="handleSaveModalForm"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
// import { mapGetters, mapActions } from "vuex";
import { PERMISSION_TO } from "./Publications";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import CustomFieldComponent from "@/view/components/CustomFieldComponent";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";
import { customFieldMixins } from "@/view/mixins/customFieldMixins.js";
//import SiteService from "@/core/services/site.service.js";

const TRANSLATED_ATRIBUTES = ["name", "slug", "intro", "text"];

export const INITIAL_TRANSLATED_CUSTOM_FIELDS = [
  "url",
  "seoTitle",
  "seoDescription",
  "featured_image",
  "card_image",
  "publication_items",
  "og_title",
  "og_description",
  "og_url",
  "og_site_name",
  "og_image",
  "publicated",
  "accordion",
];

export const INITIAL_CUSTOM_FIELDS = {
  og_type: "",
};

export const initialFormData = () => ({
  id: null,
  status: 1,
  type: 1,
  translations: {},
  categories: [],
  custom_fields: INITIAL_CUSTOM_FIELDS,
});

export default {
  name: "PublicationForm",
  props: [
    "modalData",
    "permissions",
    "statuses",
    "endPoint",
    "publicationCategoryCollection",
  ],
  components: { CustomFieldComponent },
  mixins: [formModelMixins, customFieldMixins],
  data() {
    return {
      result: null,
      languages: i18nService.userLanguages,
      selectedLocale: i18nService.userLanguages.find((item) => {
        return item.lang == i18nService.getUserActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData()),
      formValid: false,
      permissionTo: PERMISSION_TO,
      initialTransaltedCustomFields: INITIAL_TRANSLATED_CUSTOM_FIELDS,

      messages: {},
      nameRules: [
        (v) => !!v || this.$t("ERRORS.field_is_required"),
        (v) =>
          (!!v && v.length < 250) || this.$t("ERRORS.must_be_max_characters"),
      ],
      requiredRules: [(v) => !!v || this.$t("ERRORS.field_is_required")],
      editorConfig: {
        htmlEncodeOutput: false,
        entities: false,
        extraPlugins: "justify,font,copyformatting",
        filebrowserBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Files",
        filebrowserImageBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Images",
        versionCheck: false,
      },
      editorConfig2: {
        htmlEncodeOutput: false,
        entities: false,
        extraPlugins: "justify,font,copyformatting",
        filebrowserBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Files",
        filebrowserImageBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Images",
        versionCheck: false,
      },
      editorConfigIntro: {
        toolbar: [
          ["Source"],
          ["Styles", "Format", "Font", "FontSize"],
          ["Bold", "Italic"],
          ["Undo", "Redo"],
          ["About"],
        ],
        versionCheck: false,
      },
      og_types: ["website", "article"],
      formModelTypes: [1, 2, 3],
    };
  },
  computed: {
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },

    formTitle() {
      return this.formModel.id
        ? this.$t("FORMS.edit") +
            this.$helpers.getTranslated(this.formModel.translations).name
        : this.$t("MENU.NEW") + " " + this.$t("MENU.PUBLICATION");
    },

    translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },
    initialFormData() {
      return initialFormData;
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.selectedLocale = i18nService.userLanguages.find((item) => {
          return item.lang == i18nService.getUserActiveLanguage();
        });
        if (value.editedId) {
          this.loader = true;
          this.formModel = Object.assign({}, initialFormData());
          ApiService.get(this.endPoint + value.editedId)
            .then(({ data }) => {
              data.status = 1 * data.status;
              data.type = 1 * data.type;
              this.formModel = Object.assign({}, data);
              this.setTranslatedAttributes();
              // this.formModel.custom_fields = this.initCustomFields();
            })
            .catch((error) => {
              console.log("Error!: ", error);
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          this.formModel = Object.assign({}, initialFormData());
          this.setTranslatedAttributes();

          // this.formModel.custom_fields = this.initCustomFields();
          if (this.$refs.form) this.$refs.form.resetValidation();
        }
        this.resetErrorMessages();
      },
    },
  },
  methods: {
    handleSaveModalForm() {
      let model = this.deleteUnusedTranslations(
        Object.assign({}, this.formModel)
      );

      this.$refs.form.validate();
      if (!model.categories) model.categories = [];
      if (this.formValid) {
        this.resetErrorMessages();
        this.loader = true;
        if (model.id) {
          ApiService.put(this.endPoint + model.id, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model
          ApiService.post(this.endPoint, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                  console.log(this.messages);
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              // this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },
  },

  mounted() {
    this.setTranslatedAttributes();
  },
};
</script>
